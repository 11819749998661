<template>
  <div class="popup-container">
    <div class="popup-mask"></div>
    <div class="popup">
      <div class="popup-top">
        <label>Mensaje</label>
        <img src="../../assets/img/icon/close_popup.png" @click="cerrar()" />
      </div>
      <div class="popup-body popup-body-scroll">
        <div class="popup-row">
          <label class="popup-label-info">Emisor</label>
          <label>{{
            mensaje.evento.emisor != null
              ? mensaje.evento.emisor.nombre +
                " " +
                mensaje.evento.emisor.apellido
              : "-"
          }}</label>
        </div>
        <div class="popup-row-double">
          <div class="popup-row">
            <label class="popup-label-info">Tipo de evento</label>
            <label class="popup-label-capitalize">{{
              getTipo(mensaje.evento.tipo)
            }}</label>
          </div>
          <div class="popup-row">
            <label class="popup-label-info">Horario de suceso</label>
            <label class="popup-data">{{
              mensaje.evento.horario_suceso.split("T")[1].substr(0, 5)
            }}</label>
          </div>
        </div>
        <div class="popup-row">
          <label class="popup-label-info">Título de evento</label>
          <label>{{ mensaje.evento.titulo }}</label>
        </div>
        <div class="popup-row">
          <label class="popup-label-info">Descripción</label>
          <label>{{ mensaje.evento.descripcion }}</label>
        </div>
        <div class="popup-row">
          <label class="popup-label-info">Ubicación</label>
          <label>{{
            mensaje.evento.gps != null
              ? mensaje.evento.gps
              : mensaje.evento.ubicacion_alternativa != null &&
                mensaje.evento.ubicacion_alternativa != ""
              ? mensaje.evento.ubicacion_alternativa
              : "-"
          }}</label>
        </div>
        <div class="popup-row">
          <label class="popup-label-info">Imagen</label>
          <label v-if="mensaje.evento.imagen == null">-</label>
          <img
            class="popup-imagen"
            v-if="mensaje.evento.imagen != null"
            :src="$serverURL + mensaje.evento.imagen"
            @click="mostrarImagen = true"
          />
        </div>
        <div class="popup-row">
          <label class="popup-label-info">Receptores</label>
          <div v-for="receptor in mensaje.receptores" :key="receptor">
            <label class="popup-data">{{ receptor }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Info_Mensaje",
  components: {},
  props: {
    mensaje: Object,
  },
  data() {
    return {
      mostrarEliminar: false,
    };
  },
  mounted() {},
  methods: {
    cerrar() {
      this.$emit("cerrar");
    },
    getTipo(tipo) {
      let retorno = "";
      switch (tipo) {
        case "incidente":
          retorno = "Reporte de Incidente";
          break;
        case "boton_panico":
          retorno = "Botón de pánico";
          break;
        default:
          retorno = tipo;
          break;
      }
      return retorno;
    },
  },
};
</script>

<style scoped lang="scss" src="@/assets/css/views/secciones.scss"></style>
<style scoped lang="scss" src="@/assets/css/views/eventos.scss"></style>
