<template>
  <div class="view">
    <Topbar :seccion="'mensajes'"></Topbar>
    <div class="view-container">
      <div class="secciones-top">
        <label class="secciones-title">Mensajes</label>
        <Dropdown
          class="dropdown"
          :options="vigilancias"
          :placeholder="'Todas las vigilancias'"
          :paraArriba="false"
          :conInput="false"
          :name="'nombre'"
          v-on:updateOption="updateDropdownVigilancia"
        ></Dropdown>
        <div
          class="secciones-buscar"
          :class="{ 'secciones-buscar-active': searchText != '' }"
        >
          <input
            type="text"
            placeholder="Buscar"
            v-model="searchText"
            @keyup="filtrar()"
          />
          <img
            class="secciones-buscar-close"
            src="../assets/img/icon/close.png"
            @click="resetBuscar()"
          />
          <img class="secciones-buscar-img" src="../assets/img/icon/lupa.png" />
        </div>
        <DesdeHasta @desde="setDesde" @hasta="setHasta"></DesdeHasta>
        <button
          class="button-secondary secciones-descargar-btn"
          @click="mostrarDescarga = true"
        >
          <img src="../assets/img/icon/descarga.png" /> Descargar Mensajes
        </button>
      </div>
      <div class="mensajes-header">
        <div @click="elegirOpcion('fecha')">
          <label class="mensajes-header-label">Fecha</label>
          <img
            v-show="opcion == 'fecha' || opcion == 'fecha-desc'"
            class="mensajes-header-img"
            :class="{ 'mensajes-header-img-active': opcion == 'fecha-desc' }"
            src="../assets/img/icon/triangulo.png"
          />
        </div>
        <div @click="elegirOpcion('empresa')">
          <label class="mensajes-header-label">Empresa</label>
          <img
            v-show="opcion == 'empresa' || opcion == 'empresa-desc'"
            class="mensajes-header-img"
            :class="{ 'mensajes-header-img-active': opcion == 'empresa-desc' }"
            src="../assets/img/icon/triangulo.png"
          />
        </div>
        <div @click="elegirOpcion('vigilancia')">
          <label class="mensajes-header-label">Vigilancia</label>
          <img
            v-show="opcion == 'vigilancia' || opcion == 'vigilancia-desc'"
            class="mensajes-header-img"
            :class="{
              'mensajes-header-img-active': opcion == 'vigilancia-desc',
            }"
            src="../assets/img/icon/triangulo.png"
          />
        </div>
        <div @click="elegirOpcion('tipo')">
          <label class="mensajes-header-label">Tipo</label>
          <img
            v-show="opcion == 'tipo' || opcion == 'tipo-desc'"
            class="mensajes-header-img"
            :class="{ 'mensajes-header-img-active': opcion == 'tipo-desc' }"
            src="../assets/img/icon/triangulo.png"
          />
        </div>
        <div @click="elegirOpcion('remitente')">
          <label class="mensajes-header-label">Remitente</label>
          <img
            v-show="opcion == 'remitente' || opcion == 'remitente-desc'"
            class="mensajes-header-img"
            :class="{
              'mensajes-header-img-active': opcion == 'remitente-desc',
            }"
            src="../assets/img/icon/triangulo.png"
          />
        </div>
      </div>
      <div class="secciones-mensajes">
        <div
          v-for="mensaje in mensajesFiltrados"
          :key="mensaje.id"
          class="mensajes-mensaje"
          @click="elegirMensaje(mensaje)"
        >
          <label class="mensaje-label">{{
            getFecha(mensaje.evento.horario_suceso)
          }}</label>
          <label class="mensaje-label">{{
            mensaje.evento.vigilancia != null &&
            mensaje.evento.vigilancia.empresa != null
              ? mensaje.evento.vigilancia.empresa.nombre
              : "-"
          }}</label>
          <label class="mensaje-label">{{
            mensaje.evento.vigilancia != null
              ? mensaje.evento.vigilancia.nombre
              : mensaje.evento.ubicacion_alternativa != null
              ? "En vía pública"
              : "-"
          }}</label>
          <label class="mensaje-label mensaje-label-capitalize">{{
            getTipo(mensaje.evento.tipo)
          }}</label>
          <label class="mensaje-label">{{
            mensaje.evento.emisor.nombre + " " + mensaje.evento.emisor.apellido
          }}</label>
        </div>
      </div>
    </div>
    <Descarga v-show="mostrarDescarga" @cerrar="cerrarDescarga()"></Descarga>
    <Info
      v-if="mostrarInfo"
      :mensaje="mensajeElegido"
      @cerrar="cerrarInfo()"
      @get="getMensajes()"
    ></Info>
  </div>
</template>

<script>
import Topbar from "@/components/Topbar.vue";
import Descarga from "@/components/mensajes/Descarga.vue";
import Info from "@/components/mensajes/Info.vue";
import Dropdown from "@/components/Dropdown.vue";
import DesdeHasta from "@/components/DesdeHasta.vue";

export default {
  name: "Mensajes",
  components: { Topbar, Descarga, Info, Dropdown, DesdeHasta },
  data() {
    return {
      searchText: "",
      mensajes: [],
      meses: [
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dic",
      ],
      mensajesFiltrados: [],
      vigilancias: [],
      mostrarDescarga: false,
      mostrarInfo: false,
      mensajeElegido: null,
      opcion: null,
      filtroDesde: null,
      filtroHasta: null,
      filtroVigilancia: null,
    };
  },
  mounted() {
    this.getMensajes();
    this.getVigilancias();
  },
  methods: {
    updateDropdownVigilancia(payload) {
      if (payload.nombre == "Todas") {
        this.filtroVigilancia = null;
      } else {
        this.filtroVigilancia = payload.id;
      }
      this.filtrar();
    },
    cerrarDescarga() {
      this.mostrarDescarga = false;
    },
    cerrarInfo() {
      this.mostrarInfo = false;
    },
    getTipo(tipo) {
      let retorno = "";
      switch (tipo) {
        case "incidente":
          retorno = "Reporte de Incidente";
          break;
        case "boton_panico":
          retorno = "Botón de pánico";
          break;
        default:
          retorno = tipo;
          break;
      }
      return retorno;
    },
    elegirMensaje(mensaje) {
      this.mensajeElegido = mensaje;
      this.mostrarInfo = true;
    },
    setDesde(desde) {
      this.filtroDesde = desde;
      this.filtrar();
    },
    setHasta(hasta) {
      this.filtroHasta = hasta;
      this.filtrar();
    },
    getMensajes() {
      var that = this;
      this.$axios
        .get(this.$serverURL + "/api/eventos/mensajes/all/")
        .then(function (response) {
          console.log("mensajes", response.data);
          that.mensajes = response.data;
          that.mensajesFiltrados = that.mensajes;
        })
        .catch(function (response) {
          console.log(response);
        });
    },
    getVigilancias() {
      var that = this;
      this.$axios
        .get(this.$serverURL + "/api/vigilancias/all/")
        .then(function (response) {
          console.log("vigilancias", response);
          that.vigilancias = response.data;
          that.vigilancias.push({ id: true, nombre: "En vía pública" });
          that.vigilancias.push({ nombre: "Todas" });
        })
        .catch(function (response) {
          console.log(response);
        });
    },
    elegirOpcion(opcion) {
      let asc = true;
      if (this.opcion == opcion) {
        this.opcion = opcion + "-desc";
        asc = false;
      } else {
        this.opcion = opcion;
      }
      switch (opcion) {
        case "empresa":
          this.mensajes = this.ordenarJson(
            this.mensajes,
            ["evento", "turno", "vigilancia", "empresa", "nombre"],
            asc
          );
          break;
        case "vigilancia":
          this.mensajes = this.ordenarJson(
            this.mensajes,
            ["evento", "turno", "vigilancia", "nombre"],
            asc
          );
          break;
        default:
          this.mensajes = this.ordenarJson(this.mensajes, [opcion], asc);
          break;
      }
    },
    getFecha(fecha) {
      let d = new Date(fecha);
      return (
        d.getDate() + " " + this.meses[d.getMonth()] + ", " + d.getFullYear()
      );
    },
    resetBuscar() {
      this.searchText = "";
      this.filtrar();
    },
    filtrar() {
      let filtroBuscar = this.searchText
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();

      this.mensajesFiltrados = this.mensajes.filter(
        (m) =>
          (this.filtroVigilancia == null ||
            (m.evento.vigilancia != null &&
              m.evento.vigilancia.id == this.filtroVigilancia) ||
            (m.evento.vigilancia == null &&
              m.evento.ubicacion_alternativa != null &&
              this.filtroVigilancia == true)) &&
          (this.searchText == "" ||
            (m.evento.vigilancia != null &&
              m.evento.vigilancia.nombre.toLowerCase().indexOf(filtroBuscar) >
                -1) ||
            (m.evento.vigilancia != null &&
              m.evento.vigilancia.empresa != null &&
              m.evento.vigilancia.empresa.nombre
                .toLowerCase()
                .indexOf(filtroBuscar) > -1) ||
            (m.evento.tipo != null &&
              m.evento.tipo.toLowerCase().indexOf(filtroBuscar) > -1) ||
            (m.evento.emisor != null &&
              ((m.evento.emisor.email != null &&
                m.evento.emisor.email.toLowerCase().indexOf(filtroBuscar) >
                  -1) ||
                m.evento.emisor.nombre.toLowerCase().indexOf(filtroBuscar) >
                  -1 ||
                m.evento.emisor.apellido.toLowerCase().indexOf(filtroBuscar) >
                  -1)) ||
            (m.evento.titulo != null &&
              m.evento.titulo.toLowerCase().indexOf(filtroBuscar) > -1) ||
            (m.evento.vigilancia == null &&
              m.evento.ubicacion_alternativa != null &&
              "En via publica".indexOf(filtroBuscar) > -1)) &&
          (this.filtroDesde == null ||
            m.evento.horario_suceso >= this.filtroDesde) &&
          (this.filtroHasta == null ||
            m.evento.horario_suceso <= this.filtroHasta + "T23:59:59")
      );
    },
  },
};
</script>

<style scoped lang="scss" src="@/assets/css/views/secciones.scss"></style>
<style scoped lang="scss" src="@/assets/css/views/mensajes.scss"></style>
